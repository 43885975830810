import React, { FC } from 'react'
import * as StyledBasketMenu from './basketProductBox.styles'
import IconClose from 'icons/close.svg'
import { BasketProduct } from '@local-types/products/basketProduct'
import {
	getCroppedProdImg,
	renderProductCustPrice,
	shouldUseCustomerPrice,
	renderProductListPrice,
	getFixedFloatingPoint,
	getProductBaseQuantity,
} from 'helpers/productHelpers/productHelpers'
import { QuantityButtons } from './quantityButtons'
import { H5 } from 'components/base/typography/headings'
import { updateBasketItem } from 'reducers/basketReducer'
import { useDispatch } from 'react-redux'

interface Props {
	product: BasketProduct
	isCheckout?: boolean
}

// TODO: Hover effects

export const BasketProductBox: FC<Props> = ({ product: prod, isCheckout = false }) => {
	const { product, quantity } = prod
	const dispatch = useDispatch()
	const baseQuantity = getProductBaseQuantity(product, prod.isPackage)

	const handleBasketButtonClick = (q: number) => {
		const quantity = getFixedFloatingPoint(q)
		dispatch(
			updateBasketItem({
				product,
				quantity,
				selectedUnit: prod.selectedUnit,
				isPackage: prod.isPackage,
			})
		)
	}

	return (
		<StyledBasketMenu.BasketProduct>
			{!isCheckout && (
				<StyledBasketMenu.BasketRemove onClick={handleBasketButtonClick.bind(null, 0)}>
					<IconClose />
				</StyledBasketMenu.BasketRemove>
			)}
			<StyledBasketMenu.ProductImageWrapper>
				<StyledBasketMenu.ProductImage
					src={getCroppedProdImg(
						product.images[0]?.url || 'https://ss.datadwell.com/clients/ss/product_id/OP9019/600/500/0/image.png',
						180,
						180
					)}
					alt={product.images[0]?.altText}
				/>
			</StyledBasketMenu.ProductImageWrapper>
			<StyledBasketMenu.ProductContent>
				<StyledBasketMenu.ProductTitle>{product.name}</StyledBasketMenu.ProductTitle>
				{product.price && (
					<>
						{shouldUseCustomerPrice(product.price) && (
							<StyledBasketMenu.CustomPrice>
								Þitt verð {renderProductCustPrice(product.price, product.unit)}
							</StyledBasketMenu.CustomPrice>
						)}
						<StyledBasketMenu.ProductListPrice overwritten={shouldUseCustomerPrice(product.price)}>
							Listaverð: {renderProductListPrice(product.price, product.unit)}
						</StyledBasketMenu.ProductListPrice>
					</>
				)}
			</StyledBasketMenu.ProductContent>
			<StyledBasketMenu.QuantityWrapper>
				<StyledBasketMenu.Quantity>
					<QuantityButtons
						small
						isCheckout={isCheckout}
						value={quantity}
						unit={prod.selectedUnit}
						onDecrement={handleBasketButtonClick.bind(null, quantity - baseQuantity)}
						onIncrement={handleBasketButtonClick.bind(null, quantity + baseQuantity)}
					/>
				</StyledBasketMenu.Quantity>
			</StyledBasketMenu.QuantityWrapper>
		</StyledBasketMenu.BasketProduct>
	)
}
