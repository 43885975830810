import * as StyledBasket from './basket.styles'
import { FC } from 'react'
import useBasket from '../../hooks/useBasket'
import { formatCurrency } from '../../helpers/stringHelpers/stringHelpers'
import { Spinner } from 'components/base/spinner/spinner'
import { Button } from 'components/base/buttons/buttons'
import Link from 'next/link'
import { H2 } from 'components/base/typography/headings'
import {
	getBasketTotal,
	getMissingDeliveryAmount,
	FREE_DELIVERY_MIN_AMOUNT,
	getBasketListPriceWithoutVAT,
	getBasketTotalWithoutVAT,
	DELIVERY_PRICE,
} from 'helpers/basketHelpers/basketHelpers'
import BasketProductBox from 'components/products/productBoxes/basketProductBox'
import AlertIcon from 'icons/alert-circled.svg'
import { useDispatch } from 'react-redux'
import { setBasketVisibleState } from 'reducers/basketReducer'
import EmptyBasketView from './emptyBasketView'
import { GLN } from 'interfaces/global/gln'
import { BasketAccordion } from './basketAccordion'
import { Flex } from 'rebass'
import { BasketProduct } from '@local-types/products/basketProduct'
import { brandLogoMapper } from 'helpers/brandHelpers/brandHelpers'

const getDeliveryPricePercentage = (n: number) => {
	return (n / FREE_DELIVERY_MIN_AMOUNT) * 100
}

interface Props {
	checkout?: boolean
	hideBasket?: () => void
	disableHideBasket?: boolean
}

interface CompanyProductsProps {
	products: BasketProduct[]
	gln: GLN
	logoSize?: 'sm' | 'md'
}

const CompanyProducts = ({ products, gln, logoSize = 'md' }: CompanyProductsProps) => {
	const missingDeliveryAmount = getMissingDeliveryAmount(products)
	const total = getBasketTotal(products)
	const deliveryPrice = missingDeliveryAmount > 0 ? DELIVERY_PRICE : 0

	if (products.length === 0) return null

	return (
		<StyledBasket.AccordionWrapper>
			<BasketAccordion logo={brandLogoMapper[gln]} expandable isOpen smallLogo={logoSize === 'sm'}>
				<div>
					{products.map(product => (
						<BasketProductBox key={product.product.id} product={product} />
					))}
				</div>
				{missingDeliveryAmount > 0 && (
					<StyledBasket.CompanyFreeDeliveryBox>
						<StyledBasket.CompanyFreeDeliveryText>
							Vantar <span>{formatCurrency(missingDeliveryAmount)} kr.</span> fyrir fría heimsendingu
						</StyledBasket.CompanyFreeDeliveryText>
						<StyledBasket.CompanyFreeDeliveryProgress progress={getDeliveryPricePercentage(total)} />
					</StyledBasket.CompanyFreeDeliveryBox>
				)}
				{deliveryPrice > 0 && (
					<Flex justifyContent="space-between" mt="1rem">
						<StyledBasket.TotalText>Sendingarkostnaður:</StyledBasket.TotalText>
						<StyledBasket.CompanyDeliveryPrice>{`${formatCurrency(
							deliveryPrice
						)} kr.`}</StyledBasket.CompanyDeliveryPrice>
					</Flex>
				)}
				<Flex justifyContent="space-between">
					<StyledBasket.TotalText>Verð:</StyledBasket.TotalText>
					<StyledBasket.CompanyPrice>{`${formatCurrency(total + deliveryPrice)} kr.`}</StyledBasket.CompanyPrice>
				</Flex>
			</BasketAccordion>
		</StyledBasket.AccordionWrapper>
	)
}

const Basket: FC<Props> = props => {
	const { checkout, ...rest } = props
	const { products, ssProducts, hgProducts, rgProducts, productStatus, hasKgProduct, shippingPrice } = useBasket()
	const dispatch = useDispatch()
	const total = getBasketTotal(products)
	const totalWithoutVAT = getBasketTotalWithoutVAT(products)
	const listPriceWithoutVAT = getBasketListPriceWithoutVAT(products)
	const VAT = total - totalWithoutVAT

	const handleCheckoutClick = () => {
		dispatch(setBasketVisibleState(false))
	}

	if (productStatus === 'pending')
		return (
			<StyledBasket.Loading>
				<Spinner size="4rem" />
			</StyledBasket.Loading>
		)

	if (!products.length) return <EmptyBasketView {...rest} />

	return (
		<StyledBasket.Wrapper>
			<StyledBasket.ScrollContainer isCheckout={checkout || false}>
				<H2 pt="0.8rem" m="1.6rem">
					Karfa
				</H2>
				<CompanyProducts products={ssProducts} gln={GLN.SS} />
				<CompanyProducts products={rgProducts} gln={GLN.REYKJAGARDUR} />
				<CompanyProducts products={hgProducts} gln={GLN.HOLLTOGGOTT} logoSize="sm" />
				<StyledBasket.PricesWrapper>
					{listPriceWithoutVAT > totalWithoutVAT && (
						<StyledBasket.Price>
							<StyledBasket.TotalText>Listaverð</StyledBasket.TotalText>
							<StyledBasket.PriceValue inactive>{`${formatCurrency(listPriceWithoutVAT)} kr.`}</StyledBasket.PriceValue>
						</StyledBasket.Price>
					)}
					<StyledBasket.Price>
						<StyledBasket.TotalText>Þitt verð</StyledBasket.TotalText>
						<StyledBasket.PriceValue>{`${formatCurrency(totalWithoutVAT)} kr.`}</StyledBasket.PriceValue>
					</StyledBasket.Price>
					<StyledBasket.Price>
						<StyledBasket.TotalText>Vsk.</StyledBasket.TotalText>
						<StyledBasket.PriceValue>{`${formatCurrency(VAT)} kr.`}</StyledBasket.PriceValue>
					</StyledBasket.Price>
					<StyledBasket.Price>
						<StyledBasket.TotalText>Sendingarkostnaður</StyledBasket.TotalText>
						<StyledBasket.PriceValue>{`${formatCurrency(shippingPrice)} kr.`}</StyledBasket.PriceValue>
					</StyledBasket.Price>
				</StyledBasket.PricesWrapper>
			</StyledBasket.ScrollContainer>
			<StyledBasket.Checkout>
				<StyledBasket.TotalWrapper>
					<StyledBasket.TotalText size="1.8rem">{hasKgProduct ? 'Áætluð upphæð' : 'Samtals'}</StyledBasket.TotalText>
					<H2 color="secondary">{`${formatCurrency(total + shippingPrice)} kr.`}</H2>
				</StyledBasket.TotalWrapper>
				{hasKgProduct && (
					<StyledBasket.WarningWrapper>
						<StyledBasket.WarningIcon>
							<AlertIcon />
						</StyledBasket.WarningIcon>
						<StyledBasket.WarningText>
							Verð á kílóvörum í körfu eru út frá áætlaðri þyngd vörunnar og munu breytast þegar pöntun er framkvæmd.
						</StyledBasket.WarningText>
					</StyledBasket.WarningWrapper>
				)}
				{!checkout && (
					<StyledBasket.ButtonWrapper>
						<Link href="/afgreidsla">
							<Button as="a" href="/afgreidsla" secondary block onClick={handleCheckoutClick}>
								Ganga frá körfu
							</Button>
						</Link>
					</StyledBasket.ButtonWrapper>
				)}
			</StyledBasket.Checkout>
		</StyledBasket.Wrapper>
	)
}

export default Basket
