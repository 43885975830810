import React, { FC, PropsWithChildren, useState, useEffect } from 'react'
import { useSpring } from 'react-spring'
import { Flex } from 'rebass'
import { useMeasure } from 'react-use'
import * as Styles from './basketAccordion.styles'
import Arrow from 'icons/arrow-expand.svg'

type Props = PropsWithChildren<{
	logo: JSX.Element
	minimumHeight?: number
	expandable?: boolean
	isOpen?: boolean
	smallLogo?: boolean
}>

export const BasketAccordion = ({
	children,
	logo,
	expandable = true,
	isOpen = true,
	minimumHeight = 0,
	smallLogo = false,
}: Props) => {
	const defaultHeight = minimumHeight || 0
	const [open, toggle] = useState(isOpen || false)
	const [ref, { height }] = useMeasure()
	const [contentHeight, setContentHeight] = useState(defaultHeight)

	useEffect(() => {
		setContentHeight(height)
		const setHeight = () => {
			setContentHeight(height)
		}
		if (window) {
			window.addEventListener('resize', setHeight)
		}
		return window.removeEventListener('resize', setHeight)
	}, [height])

	const expand = useSpring({
		height: open ? `${contentHeight}px` : `${defaultHeight}px`,
	})
	const spin = useSpring({
		config: { friction: 10 },
		transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
	})

	return (
		<>
			<Styles.HeadingContainer onClick={expandable ? () => toggle(!open) : undefined} small={smallLogo} open={open}>
				{logo}
				{expandable ? (
					<Styles.ExpandButton type="button" style={spin} aria-label="expand-container">
						<Arrow />
					</Styles.ExpandButton>
				) : null}
			</Styles.HeadingContainer>
			<Flex justifyContent="center" flexDirection="column">
				<Styles.Accordion style={expand}>
					<Styles.Content ref={ref}>{children}</Styles.Content>
				</Styles.Accordion>
			</Flex>
		</>
	)
}
