import styled, { css, keyframes } from 'styled-components'

const spin = keyframes`
	0% {
		transform: rotate(0);
		animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
	}
	50% {
		transform: rotate(180deg);
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}
	100% {
		transform: rotate(360deg);
	}
`

interface SpinnerProps {
	size?: string
}

export const Spinner = styled.div<SpinnerProps>`
	width: ${({ size }) => size || '4rem'};
	height: ${({ size }) => size || '4rem'};
	animation: ${spin} 1000ms infinite;
	background: center center no-repeat;
	background-size: cover;
	border-radius: 50%;
	border-style: solid;
	border-width: 1.5px;
	${({ theme }) => css`
		border-color: ${theme.colors.primary} ${theme.colors.primary} ${theme.colors.primary} rgba(0, 0, 0, 0.1);
	`}
`
