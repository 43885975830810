import styled from 'styled-components'
import { animated } from 'react-spring'
import { css } from 'styled-components'

export const Accordion = styled(animated.div)`
	border: none;
	overflow: hidden;
	position: relative;
`

interface HeadingProps {
	small?: boolean
	open?: boolean
}

export const HeadingContainer = styled.div<HeadingProps>`
	display: flex;
	flex-direction: row;
	align-content: center;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;

	${({ open }) =>
		open &&
		css`
			border-bottom: 1px solid ${({ theme }) => theme.colors.borderAlt};
		`}

	${({ small }) =>
		small &&
		css`
			img {
				width: 10rem;
			}
		`}
`

export const ExpandButton = styled(animated.button)`
	background: transparent;
	border: none;
	height: 5rem;
	width: 5rem;
	cursor: pointer;

	svg path {
		transition: stroke 200ms;
	}

	&:hover {
		svg path {
			stroke: ${({ theme }) => theme.colors.secondary};
		}
	}
`

export const Content = styled.div`
	padding-top: 0.3125rem;
	min-width: 3.125rem;
	display: flex;
	flex-direction: column;
`
