import styled, { css } from 'styled-components'
import { withFancyScrollbar } from 'helpers/styleHelpers/styleHelpers'

export const BasketIconWrapper = styled.div``

export const Wrapper = styled.section`
	height: 100%;
	max-height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`

export const EmptyCartWrapper = styled.section`
	height: 100%;
	max-height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
`

export const Loading = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`

export const EmptyBasketIndication = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	height: 60%;
`

export const TotalWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 1.6rem 3rem 1.6rem 3rem;
	border-top: 1px solid ${({ theme }) => theme.colors.borderLight};
	box-shadow: 0 -1rem 2rem rgba(219, 229, 231, 0.3);
`

interface IsCheckoutProps {
	isCheckout: boolean
}

export const ScrollContainer = styled.div<IsCheckoutProps>`
	flex-grow: 1;
	overflow-y: auto;
	${withFancyScrollbar};

	${({ isCheckout }) =>
		isCheckout &&
		css`
			max-height: 110rem;
		`}
`

export const PricesWrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 1.6rem 3rem;
`

export const Price = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 0.5rem 0;
`

export const CompanyFreeDeliveryBox = styled.div`
	margin-top: 0.5rem;
	background-color: #f9fbff;
	padding: 1.5rem;
	text-align: center;
`

export const CompanyFreeDeliveryText = styled.p`
	font-size: 14px;
	font-weight: 400;
	color: ${({ theme }) => theme.colors.textDark};

	span {
		font-weight: 900;
		color: ${({ theme }) => theme.colors.secondary};
	}
`

export const CompanyDeliveryPrice = styled.div`
	font-size: 18px;
	font-weight: 500;
	color: ${({ theme }) => theme.colors.textDark};
`

export const CompanyPrice = styled.div`
	font-size: 20px;
	font-weight: 900;
	color: ${({ theme }) => theme.colors.secondary};
`

export const CompanyFreeDeliveryProgress = styled.div<{ progress: number }>`
	width: 100%;
	height: 4px;
	position: relative;
	background-color: ${({ theme }) => theme.colors.primaryAlt};
	margin-top: 0.5rem;

	::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: ${({ progress }) => progress}%;
		height: 100%;
		background-color: ${({ theme }) => theme.colors.primary};
	}
`

export const AccordionWrapper = styled.div`
	margin: 1.6rem;
	padding: 1rem;
	border-radius: 4px;
	border: 1px solid ${({ theme }) => theme.colors.borderXLight};
`

interface PriceValueProps {
	inactive?: boolean
}

export const PriceValue = styled.div<PriceValueProps>`
	font-size: 1.6rem;
	font-weight: 600;

	${({ inactive }) =>
		inactive &&
		css`
			color: ${({ theme }) => theme.colors.textLight};
			line-height: 1.5;
			text-decoration: line-through;
		`}
`

export const WarningWrapper = styled.div`
	display: flex;
	padding: 1.6rem 3rem;
`

export const FreeDeliveryText = styled.p`
	display: flex;
	justify-content: center;
	text-align: center;
	font-weight: 800;
	padding: 2rem 0;
	color: ${({ theme }) => theme.colors.green};
	font-size: 1.3rem;
`

export const WarningText = styled.div`
	padding: 0 2.4rem;
	font-size: 1.4rem;
`

export const WarningIcon = styled.div`
	padding-top: 0.5rem;
`

export const Checkout = styled.div`
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
`
interface TotalProps {
	size?: string
}

export const TotalText = styled.div<TotalProps>`
	display: flex;
	align-items: center;
	font-weight: 900;
	font-size: ${({ size }) => size || '1.6rem'};
	line-height: 120%;
	padding-bottom: 0.3rem;
	color: ${({ theme }) => theme.colors.textDark};
`

export const ButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	padding: 1.6rem 3rem;
	border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};
`
