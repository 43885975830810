import styled, { css } from 'styled-components'

export const BasketProduct = styled.div`
	position: relative;
	display: flex;
	border-bottom: 1px solid ${({ theme }) => theme.colors.borderXLight};
	min-height: 7rem;
	margin-bottom: 1rem;
`

export const ProductImage = styled.img`
	max-height: 100%;
`

export const ProductContent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: 17.5rem;
	margin: 0.5rem 0rem 0.5rem 0rem;
	padding: 0.5rem 0rem 0.5rem 1rem;
`

export const ProductTitle = styled.div`
	font-weight: 700;
	font-size: 1.6rem;
	line-height: 1.2;
	padding-bottom: 0.8rem;
	color: ${({ theme }) => theme.colors.textDark};
`

interface ListPriceProps {
	overwritten: boolean
}

export const ProductListPrice = styled.div<ListPriceProps>`
	${({ overwritten }) =>
		overwritten &&
		css`
			text-decoration-line: line-through;
		`}
	font-size: 1.2rem;
	font-weight: 600;
	line-height: 130%;
`

export const ProductImageWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	align-self: center;
	width: 6rem;
	height: 6rem;
	min-width: 6rem;
	min-height: 6rem;
	margin: 0.5rem;
	margin-right: 1rem;
`
export const BasketRemove = styled.div`
	position: absolute;
	display: flex;
	top: 0;
	right: 0;
	padding: 1rem;
	cursor: pointer;

	svg {
		fill: ${({ theme }) => theme.colors.textLight};
	}

	&:hover {
		svg {
			fill: ${({ theme }) => theme.colors.secondary};
		}
	}
`

export const QuantityWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 1rem;
	margin-left: auto;
	margin-right: 2rem;
	min-width: 7.5rem;
`

export const CustomPrice = styled.div`
	font-size: 14px;
	color: ${({ theme }) => theme.colors.secondary};
	font-weight: 700;
	line-height: 1;
`

export const Quantity = styled.div`
	position: absolute;
	bottom: 0.5rem;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 7rem;
	height: 3rem;
	font-size: 1.4rem;
	font-weight: 800;
	line-height: 130%;
`
