import React, { FC } from 'react'
import styled, { css } from 'styled-components'
import IconMinus from 'icons/minus.svg'
import IconPlus from 'icons/plus.svg'
import { Label } from '../../../base/forms/label'
const Quantity = styled.div`
	flex-grow: 1;
	text-align: center;
`

const QuantityIcon = styled.div`
	svg {
		height: 2rem;
		width: 2rem;
		fill: ${({ theme }) => theme.colors.primary};
		cursor: pointer;
		transition: fill 200ms;

		&:hover {
			fill: ${({ theme }) => theme.colors.secondary};
		}
	}
`
interface WrapperProps {
	small?: boolean
}

const QuantityWrapper = styled.div<WrapperProps>`
	display: flex;
	justify-content: center;
	align-items: 'center';
	padding: ${({ small }) => (small ? '0' : '2rem 3rem')};
	line-height: normal;
	user-select: none;

	${Quantity} {
		${({ small }) =>
			small &&
			css`
				label {
					font-size: 1.4rem;
					font-weight: 800;
					min-width: 5rem;
				}
			`}
	}

	${QuantityIcon} {
		${({ small }) =>
			small &&
			css`
				svg {
					height: 1.8rem;
					width: 1.8rem;
					fill: ${({ theme }) => theme.colors.primaryDark};

					&:hover {
						fill: ${({ theme }) => theme.colors.secondary};
					}
				}
			`}
	}
`

interface Props {
	value: number
	unit: string
	onIncrement: () => void
	onDecrement: () => void
	small?: boolean
	isCheckout: boolean
}

export const QuantityButtons: FC<Props> = ({ value, unit, onIncrement, onDecrement, small, isCheckout }) => {
	return (
		<QuantityWrapper small={small}>
			{!isCheckout && (
				<QuantityIcon onClick={onDecrement}>
					<IconMinus />
				</QuantityIcon>
			)}
			<Quantity>
				<Label>{`${value} ${unit}.`}</Label>
			</Quantity>
			{!isCheckout && (
				<QuantityIcon onClick={onIncrement}>
					<IconPlus />
				</QuantityIcon>
			)}
		</QuantityWrapper>
	)
}
