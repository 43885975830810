import styled from 'styled-components'
import { styleParams } from 'helpers/styleHelpers/styleHelpers'

export const Label = styled.label`
	display: block;
	color: ${({ theme }) => theme.colors.textDark};
	font-weight: 500;
	${styleParams};
`
export const StrongLabel = styled.label`
	display: block;
	color: ${({ theme }) => theme.colors.textDark};
	font-weight: 800;
	${styleParams};
`
