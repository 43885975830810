import * as StyledBasket from './basket.styles'
import React, { FC } from 'react'
import { H4 } from 'components/base/typography/headings'
import EmptyBasketIcon from 'icons/emptyBasketIcon.svg'
import { Button } from 'components/base/buttons/buttons'

interface Props {
	disableHideBasket?: boolean
	hideBasket?: () => void
}

const EmptyBasketView: FC<Props> = ({ disableHideBasket, hideBasket }) => {
	return (
		<StyledBasket.EmptyCartWrapper>
			<StyledBasket.EmptyBasketIndication>
				<EmptyBasketIcon />
				<H4 mt="2rem" mb="3.5rem">
					Karfan er tóm
				</H4>
				{!disableHideBasket && (
					<Button primary onClick={hideBasket}>
						Loka glugga
					</Button>
				)}
			</StyledBasket.EmptyBasketIndication>
		</StyledBasket.EmptyCartWrapper>
	)
}

export default EmptyBasketView
